






































import { get_device, post_user_device } from "@/api";
import { deviceType } from "@/api/api.types";
import { VuetifyForm } from "@/shared/types";
import { required } from "@/shared/validation";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ name: "device-assign" })
export default class DeviceAssign extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  required = required;

  /* Variables */

  formData = {
    action: "addPerson",
    device: "",
    targetName: "Employee",
  };

  deviceList = [] as deviceType["data"]["_embedded"]["device"];

  targetNameList = [
    { value: "Employee", text: "Employee" },
    { value: "Allowed", text: "Allowed" },
    { value: "NotAllowed", text: "Not Allowed" },
  ];

  /* Computed */
  /* Methods */

  @Emit("submit")
  async submit(): Promise<string | undefined> {
    try {
      const resp = await post_user_device({
        ...this.formData,
        userProfile: this.value,
      });
      Notif.notif_warning("Waiting for Device Response...");
      return resp.data.uuid;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.close();
    }
  }

  @Emit("input")
  close(): null {
    (this.$refs.formData as VuetifyForm).reset();
    return null;
  }

  async fetchDevice(): Promise<void> {
    try {
      const resp = await get_device();
      this.deviceList = resp.data._embedded.device;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchDevice();
  }
}
